body {
    top: 0 !important;
    &>div.skiptranslate {
        display: none;
    }
    #google_translate_element {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 100000000;
    }
}
