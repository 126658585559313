$primary: $primary-color;
$secondary: $secondary-color;

$greed-accept: rgba($light-gray-color, 0.5);

$series-a: $secondary-color;
$series-b: #ff7200;
$series-c: #16cd12;
$series-d: $light-primary-color;
$series-e: #ff5722;
$series-f: #e91e63;

// Link
$link-text: $primary !default;
$link-hover-text: $secondary !default;

$grid-filter-cell-padding-y: 0;
$grid-filter-cell-padding-x: 4px;

$grid-header-cell-padding-y: 6px;

$grid-header-padding-x: 4px;
$grid-header-padding-y: 6px;

$grid-header-border: rgba(0, 0, 0, 0);

$grid-cell-padding-y: 0;
$grid-cell-padding-x: 4px;

$grid-alt-bg: rgba($light-gray-color, 0.5);

$font-family: $font-family-title;

$input-border: $border-color;
$input-hovered-border: $border-color;
$input-border-width: 2px;
$font-size-lg: 13px;

$input-text: $muted-color;

$calendar-cell-size: 32px;
$calendar-view-gap: 20px;
$calendar-multi-view-gap: 0;

$grid-hovered-bg: rgba(0, 0, 0, 0);

$grid-chrome-border: transparent;

$grid-box-shadow: inset 0px -3px 1px -2px rgba(0, 75, 117, 0.2),
inset 0px -2px 2px 0px rgba(0, 75, 117, 0.14),
inset 0px -1px 5px 0px rgba(0, 75, 117, 0.12);

$grid-box-shadow-header: 0px 3px 1px -2px rgba(0, 75, 117, 0.2),
0px 2px 2px 0px rgba(0, 75, 117, 0.14),
0px 1px 5px 0px rgba(0, 75, 117, 0.12),
inset 1px 2px 2px 0px rgba(0, 75, 117, 0.14),
inset -1px 1px 5px 0px rgba(0, 75, 117, 0.12);

$grid-height: 1100px;