// button --------------------------------------
.mat-primary,
.mat-accent {
    &.mat-raised-button,
    &.mat-stroked-button,
    &.mat-button {
        font-size: 16px;
        font-family: $font-family-title;
        font-weight: $font-weight-medium;
        border-radius: 20px;
        padding: 0 36px;
        @include text-truncate();
    }

    &.mat-raised-button,
    &.mat-button {
        line-height: 40px;
    }

    &.mat-stroked-button {
        line-height: 36px;
        border: 2px solid currentColor;
        background: white;
        &.inverted {
            border-color: white;
            color: white;
            background: $primary-text-color;
            &:hover {
                background-color: $light-secondary-color;
            }
        }
    }
}
.mat-accent {
    &.mat-raised-button {
        &:hover {
            background-color: rgba($success-color, 0.7);
        }
        &[disabled] {
            background-color: rgba($success-color, 0.3);
            color: rgba($white, 0.6);
        }
    }
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    border-radius: 20px;
    border: 2px solid $primary-color;
    .mat-button-toggle {
        font-size: 16px;
        font-family: $font-family-title;
        font-weight: $font-weight-medium;
        padding: 0 36px;
        line-height: 36px;
        background: white;
        &-appearance-standard .mat-button-toggle-label-content {
            line-height: 36px;
            padding: 0 6px;
        }
        @include text-truncate();
        &+.mat-button-toggle {
            border-left: 2px solid $primary-color;
        }
        &:hover .mat-button-toggle-focus-overlay {
            opacity: 0.04;
        }
        &-checked {
            color: white;
            background: $primary-color;
            .mat-button-toggle-disabled {
                background-color: rgba($primary-color, 0.3);
                color: rgba($white, 0.6);
                pointer-events: none;
            }
            &:hover {
                background-color: $light-secondary-color;
            }
        }
        &.icon-button {
            padding: 0 6px;
        }
        &-disabled {
            pointer-events: none;
            .mat-button-toggle-focus-overlay {
                opacity: 0.1;
            }
            color: rgba($primary-color, 0.6);
        }
    }
}

.mat-primary {
    &.mat-raised-button {
        &:hover {
            background-color: $light-secondary-color;
        }
        &[disabled] {
            background-color: rgba($light-secondary-color, 0.3);
            color: rgba($white, 0.6);
        }
    }
}

.mat-icon-button.mat-icon-button--24 {
    width: 24px;
    height: 24px;
    line-height: 24px;

    & .mat-icon,
    & i {
        line-height: 16px;
    }
}

.mat-button .mat-button-focus-overlay {
    background: rgba($white, 0);
}

// field --------------------------------------

.mat-form-field-appearance-legacy.mat-form-field-invalid:not(.mat-focused) .mat-form-field-ripple {
    height: 2px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 2px !important;
    background-color: $border-color !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
    color: $body-color;
}

.mat-form-field {
    width: 100%;

    .mat-form-field-infix {
        padding-bottom: 0;
    }
    .mat-input-element {
        padding-bottom: 8px;
        font-weight: bold;
    }
    &.mat-focused {
        & .mat-form-field-ripple {
            background-color: $primary-text-color;
        }
        & .mat-form-field-label {
            color: $primary-text-color;
        }
    }
    .mat-input-element {
        caret-color: $primary-color;
    }
}

.mat-select-trigger {
    padding-bottom: 8px;
}

//  ripple----------------------------------

.mat-ripple-element {
    background-color: rgba($secondary-color, 0.25) !important;
}

//  checkbox----------------------------------

.mat-checkbox {
    .mat-checkbox-background,
    .mat-checkbox-frame {
        border-radius: 4px;
    }
    .mat-checkbox-frame {
        border-color: $border-color;
    }
    .mat-checkbox-checkmark {
        padding: 2px;
    }
    .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
        margin-top: 2px;
    }
    .mat-checkbox-layout {
        white-space: normal;
        margin-bottom: 12px;
    }
    & .mat-ripple-element {
        background-color: rgba($secondary-color, 0.15) !important;
    }
}

.mat-checkbox.mat-checkbox--sm {
    .mat-checkbox-checkmark {
        padding: 1px;
    }

    .mat-checkbox-inner-container {
        width: 14px;
        height: 14px;
        margin-top: 1px;
        vertical-align: top;
    }

    .mat-checkbox-layout {
        white-space: normal;
        margin-bottom: 12px;

        & .mat-checkbox-label {
            font-size: 13px;
            line-height: 16px;
        }
    }

    .mat-checkbox-ripple {
        position: absolute;
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        height: 30px;
        width: 30px;
        border-radius: 50%;
    }
}

// -------------------------------------------------------------

.mat-pseudo-checkbox {
    color: $border-color;
}

.mat-option.mat-option--xs {
    .mat-pseudo-checkbox-checked::after {
        border-left-width: 1px;
    }
    .mat-pseudo-checkbox {
        margin-top: 4px;
        width: 14px;
        height: 14px;
        border-radius: 4px;
        &:after {
            top: 4px;
            width: 8px;
            height: 4px;
            border-bottom-width: 1px;
        }
    }
}

//  radio----------------------------------

.mat-radio-group {
    .mat-radio-label {
        align-items: flex-start;
    }

    .mat-radio-container {
        top: 3px;
    }

    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
        width: 18px;
        height: 18px;
        border-color: $border-color;
    }

    .mat-radio-checked {
        .mat-radio-inner-circle {
            transform: scale(0.4);
        }

        .mat-radio-label-content {
            color: $primary-text-color;
        }
    }
    .mat-radio-label-content {
        white-space: normal;
        text-align: left;
    }
    & .mat-ripple-element {
        background-color: rgba($secondary-color, 0.15) !important;
    }
}

.mat-radio-group.mat-radio-group--sm {
    .mat-radio-label {
        align-items: flex-start;
        margin-bottom: 12px;
        font-size: 13px;
    }

    .mat-radio-container {
        top: 1px;
    }

    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
        width: 14px;
        height: 14px;
    }

    .mat-radio-ripple {
        position: absolute;
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        height: 30px;
        width: 30px;
        border-radius: 50%;
    }
}

.mat-radio-group.mat-radio-group--lg {
    .mat-radio-container {
        top: 0;
    }

    .mat-radio-container,
    .mat-radio-outer-circle,
    .mat-radio-inner-circle {
        width: 24px;
        height: 24px;
    }
}

// ----------------------------------------------
.mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
        display: none;
    }

    &.howSteps {
        .mat-horizontal-stepper-header-container {
            display: flex;
        }
        .mat-horizontal-stepper-content {
            padding: 0 24px;
        }
    }
}

.mat-stepper-horizontal.mat-stepper-horizontal--xs {
    .mat-horizontal-stepper-header-container {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 50px;
            top: 26px;
            width: calc(100% - 155px);
            height: 2px;
            background-color: $light-primary-color;
            display: block;
        }
    }

    .mat-stepper-horizontal-line {
        display: none;
    }

    .mat-step-header {
        flex: auto;
        display: flex;
        flex-direction: column;
        padding: 0 4px;

        .mat-step-icon,
        .mat-step-icon-not-touched {
            width: 10px;
            height: 10px;
            margin: 22px auto 12px;
            background-color: $light-primary-color;

            & .mat-icon {
                display: none;
            }

            .ng-star-inserted {
                font-size: 0;
            }
        }

        .mat-step-label {
            font-size: 16px;
            font-weight: normal;

            &.mat-step-label-active {
                font-size: 16px;
                color: $secondary-color;

                &.mat-step-label-selected {
                    font-weight: $font-weight-bold;
                    color: $primary-color;
                }
            }
        }
    }

    .mat-step-header[aria-selected="true"] {
        & .mat-step-icon {
            width: 14px;
            height: 14px;
            margin: 20px auto 10px;
            background-color: $secondary-color;
        }
    }
}

.mat-stepper-horizontal .mat-horizontal-content-container {
    padding: 0;
}
.mat-stepper-horizontal--lg.howSteps .mat-horizontal-content-container {
    margin-top: 50px;
}

.mat-step-header {
    pointer-events: none;

    &.cdk-keyboard-focused,
    &.cdk-program-focused,
    &:hover {
        background-color: transparent;
    }
}

.mat-stepper-horizontal.mat-stepper-horizontal--lg {
    & .mat-step-header[aria-selected*="true"] {
        .mat-step-icon {
            background-color: $secondary-color;
        }
    }

    & .mat-step-header {
        .mat-step-icon,
        .mat-step-icon-not-touched {
            background-color: $light-primary-color;
        }
    }

    .mat-step-icon,
    .mat-step-icon-not-touched {
        width: 46px;
        height: 46px;
        margin-right: 16px;
    }

    .mat-step-label {
        font-size: 20px;
        font-weight: bold;

        &.mat-step-label-active {
            color: $light-secondary-color;
        }

        &.mat-step-label-selected {
            color: $primary-color;
        }
    }

    .mat-stepper-horizontal-line {
        border-top: 2px solid $light-primary-color;
        position: relative;

        &:after {
            position: absolute;
            content: "";
            right: -6px;
            top: -6px;
            border: 6px solid transparent;
            border-left: 6px solid $light-primary-color;
        }
    }
}

// --------------------------
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-option:focus:not(.mat-option-disabled) {
    background: $primary-color;
    color: $white;
}

.mat-option:hover:not(.mat-option-disabled) {
    background: rgba($light-secondary-color, 0.5);
    color: $white;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
    background: $primary-color;
    color: $white;
}

.mat-menu-panel {
    margin-top: 8px;
    min-width: 90px !important;
    // position: relative;
    // position: fixed !important;

    .mat-menu-item {
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        font-weight: $font-weight-bold;
        color: $primary-text-color;

        &.active {
            background: $primary-color;
            color: $white;
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    .mat-menu-content {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    &.header-menu {
        min-width: 108px !important;
        max-width: 300px;
        & .mat-menu-item {
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            color: $primary-text-color;
            font-weight: $font-weight-medium;
            &.active {
                background: $primary-color;
                color: $white;
            }
            &:hover:not([disabled]):not(.active) {
                background: rgba($light-secondary-color, 0.5);
                color: $white;
            }
        }
    }
}

.mat-select-arrow {
    color: $muted-color;
}

.mat-form-field-suffix {
    height: 18px;
    width: 18px;
    .text-icon {
        font-weight: $font-weight-bold;
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 8px;
        vertical-align: bottom;
        display: inline-block;
    }
    .mat-icon-button {
        height: 18px;
        width: 18px;
        line-height: 1;
    }
}

// ------------------------ datapicker

.mat-calendar-body-today.mat-calendar-body-selected {
    box-shadow: none;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    background: rgba($light-secondary-color, 0.5);
    color: $white;
}

// ---------------------------------

.mat-drawer {
    position: fixed;
}

.mat-sidenav-container {
    & .mat-drawer-content {
        overflow: hidden !important;
    }
}
.mat-drawer-container {
    z-index: 10 !important;
    background: $body-bg;
    color: $body-color;
    &.mat-drawer-opened {
        height: 100vh;
    }
}

// -----------------------------------------

.mat-elevation-header {
    box-shadow: $box-shadow-base-2;
}

// -------------------------------

.suffix-24 {
    .mat-form-field-flex {
        align-items: flex-end;
    }

    .mat-form-field-suffix {
        width: 24px;
        height: 24px;
        margin-bottom: 4px;

        .mat-icon-button {
            width: 24px;
            height: 24px;
        }
    }
}

// ---------------------------------------

.mat-menu-panel.multiselect-filter {
    max-height: 300px;
    max-width: 400px;

    .mat-checkbox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .mat-checkbox-layout {
            margin-bottom: 0;
            padding: 4px 8px;
            @include text-truncate();
            color: $primary-text-color;
            font-weight: $font-weight-bold;
            display: inline-block;
            width: 100%;
        }
        .mat-checkbox-inner-container {
            margin: 5px 6px 0 0;
        }

        &:hover {
            background: rgba($light-secondary-color, 0.5);

            .mat-checkbox-label {
                color: $white;
            }
        }

        &.mat-checkbox--sm-img {
            .mat-checkbox-layout {
                padding: 3px 8px;
            }
            img {
                vertical-align: top;
            }
        }
    }
}
