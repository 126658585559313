.coming-soon {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: $primary-text-color;
    .coming-soon__inner,
    app-root {
        flex: 1;
        display: flex !important;
        flex-direction: column;
        text-align: center;
    }

    &__inner {
        background-image: url("/assets/image/svg/bwb.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 50% 70%;

        &__background {
            position: fixed;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            background-image: url("/assets/image/svg/coming-soon/bg-1.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }

        &__survey-button {
            margin-top: 20px;
            display: block;

            a {
                color: $success-color;
                line-height: 21px;
                font-weight: $font-weight-medium;
                margin-right: 10px;

                &:hover {
                    opacity: 0.7;
                }
            }

            i {
                margin-right: 5px;
                vertical-align: middle;
            }
        }

        &__survey {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 3;
            display: none;
            background: rgba(255, 255, 255, 0.9);
            .previous-survey {
                width: 630px;
                background-color: #fff;
                border: 1px solid #dadce0;
                border-radius: 8px;
                margin-bottom: 12px;
                padding: 24px;
                padding-top: 22px;
                position: relative;
                text-align: left;
                padding-top: 36px;
                margin: auto auto 30px;
                .top-line {
                    background-color: rgb(103, 58, 183);
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    height: 10px;
                    left: -1px;
                    position: absolute;
                    top: -1px;
                    width: calc(100% + 2px);
                }
                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 20px;
                    color: #000;

                    i {
                        margin-right: 5px;
                    }
                }
            }
            iframe {
                max-width: 100%;
            }

            iframe,
            video {
                margin: auto;
            }

            .previous-survey+iframe {
                margin: 0 auto auto;
            }

            video {
                max-width: 800px;
            }

            &[show-form] {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            &__close {
                cursor: pointer;

                &:hover {
                    opacity: 0.7;
                }

                position: absolute;
                right: 3%;
                top: 3%;

                i {
                    font-size: 45px;
                }
            }
        }
    }

    .tooltip {
        display: inline-block;

        i {
            cursor: pointer;
            vertical-align: middle;
            color: $yellow;
        }

        .tooltip-text {
            visibility: hidden;
            opacity: 0;
            width: 550px;
            max-width: 100%;
            background: white;
            color: rgba(0, 0, 0, 0.87);
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            border-radius: 4px;
            padding: 16px;
            font-size: 14px;
            position: absolute;
            z-index: 1;
            bottom: 100%;
            right: 0;
            line-height: 24px;
            text-align: left;
            white-space: normal;
        }

        &:hover {
            .tooltip-text {
                opacity: 1;
                visibility: visible;
            }

            i {
                opacity: 0.7;
            }
        }
    }

    @media screen and (max-width: map-get($breakpoints, md)) and (min-height: 650px) {
        .coming-soon__inner__background {
            background-size: 115% 100%;
        }
    }

    @media screen and (max-width: map-get($breakpoints, md)) {
        .coming-soon__inner {
            background-size: 85% 70%;
        }

        .coming-soon__inner__background {
            background-image: url("/assets/image/svg/coming-soon/bg-2.svg");
        }
    }

    @media screen and (max-width: map-get($breakpoints, sm)) {
        .coming-soon__inner {
            background-size: 70% 70%;
        }

        .coming-soon__inner__background {
            background-image: url("/assets/image/svg/coming-soon/bg-3.svg");
        }

        .coming-soon__inner__survey {
            .previous-survey {
                max-width: 100%;
                margin-top: 150px;
            }
            video {
                max-width: 100%;
            }
        }
    }

    .header {
        position: relative;

        .slogan {
            font-size: 20px;
            line-height: 46px;
            position: absolute;
            right: 18%;
            top: 70px;
        }

        .logo {
            font-family: $font-family-title;
            position: absolute;
            left: 18%;
            top: 60px;
            height: 105px;
            display: flex;
            align-content: center;
            align-items: center;

            img {
                margin-right: 10px;
                width: 90px;
            }

            &__text-holder {
                display: flex;
                flex-direction: column;
                text-align: left;
            }

            &__text {
                line-height: 60px;
                font-size: 80px;
                font-weight: $font-weight-light;
            }

            &__slogan {
                font-size: 9px;
                margin-left: 5px;
                white-space: nowrap;
            }
        }

        @media screen and (max-width: 1440px) {
            .logo {
                left: 10%;
            }

            .slogan {
                right: 10%;
            }
        }

        @media screen and (max-width: map-get($breakpoints, lg)) {
            .slogan {
                top: 75px;
                font-size: 18px;
            }

            .logo {
                top: 75px;
            }
        }

        @media screen and (max-width: map-get($breakpoints, md)) {
            .slogan {
                top: 40px;
                right: 20px;
                font-size: 18px;
            }

            .logo {
                top: 10px;
                left: 20px;
            }
        }

        @media screen and (max-width: map-get($breakpoints, sm)) {
            .slogan {
                top: 15px;
                right: 20px;
                font-size: 16px;

                &__text {
                    display: none;
                }
            }

            .logo {
                top: 10px;
                height: 60px;
                font-size: 20px;
                left: 20px;

                img {
                    margin-right: 10px;
                    width: 50px;
                }

                &__text {
                    line-height: 40px;
                    font-size: 40px;
                    font-weight: $font-weight-medium;
                }

                &__slogan {
                    font-size: 9px;
                    margin-left: 3px;
                }
            }
        }

        @media screen and (max-height: 850px) and (min-width: map-get($breakpoints, md)) {
            .slogan {
                top: 30px;
            }

            .logo {
                top: 5px;
            }
        }
    }
    .content {
        &__inner {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -65%);
            margin-top: -30px;
        }

        &__bottom {
            position: relative;
            white-space: nowrap;

            br {
                display: none;
            }
        }

        &__bottom,
        &__top {
            font-size: 20px;
            line-height: 36px;
        }

        &__middle {
            font-size: 42px;
            line-height: 58px;
            font-weight: $font-weight-bold;
            margin: 15px 0 0 0;
        }

        &__button {
            margin-top: 10px;

            a {
                margin-left: 18px;
                display: inline-block;
                margin-top: 5px;
            }
        }

        display: flex;
        flex: 1;
    }

    .footer {
        padding: 20px 50px;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        z-index: 2;

        .powered-by {
            font-size: 14px;
            color: $primary-color;
            padding: 0 16px;

            * {
                margin-right: 5px;
                display: inline-block;
                line-height: 28px;
                vertical-align: middle;

                img {
                    margin-top: -3px;
                }
            }
        }
    }

    @media screen and (max-width: map-get($breakpoints, lg)) {
        .content {
            &__inner {
                margin-top: -20px;
            }

            &__bottom,
            &__top {
                font-size: 18px;
                line-height: 34px;
            }

            &__middle {
                font-size: 36px;
                margin: 5px 0;
                line-height: 48px;
            }

            &__button {
                margin-top: 5px;
            }
        }

        &__inner {
            &__survey-button {
                margin-top: 10px;
            }
        }
    }
    @media screen and (max-width: map-get($breakpoints, md)) {
        .content {
            &__inner {
                white-space: nowrap;
            }

            &__bottom,
            &__top {
                font-size: 18px;
                line-height: 34px;
            }

            &__middle {
                margin: 10px 0 0 0;
                font-size: 36px;
                line-height: 48px;
            }
        }
    }
    @media screen and (max-width: map-get($breakpoints, sm)) {
        .content {
            &__inner {
                margin-top: 30px;
                white-space: normal;
                min-width: 80%;
                transform: translate(-50%, -50%);
            }

            &__bottom,
            &__top {
                font-size: 16px;
                line-height: 24px;
            }

            &__bottom {
                br {
                    display: block;
                }

                .tooltip-text {
                    max-height: 30vh;
                    padding: 8px;
                    bottom: 28px;
                    font-size: 13px;
                    line-height: 18px;
                    overflow-y: auto;
                }
            }

            &__middle {
                margin: 5px 0 5px 0;
                font-size: 23px;
                line-height: 26px;
            }
        }
        .footer {
            font-size: 12px;
            line-height: 18px;

            .powered-by {
                font-size: 12px;
            }
        }
        .header {
            .slogan {
                span {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-height: 850px) and (min-width: map-get($breakpoints, md)) {
        .content {
            &__inner {
                margin-top: -20px;
            }

            &__bottom,
            &__top {
                font-size: 22px;
                line-height: 36px;
            }

            &__middle {
                font-size: 40px;
                line-height: 32px;
            }
        }

        .footer {
            padding: 20px;
            max-width: 350px;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: map-get($breakpoints, sm)) and (max-height: 750px) {
        .footer {
            padding: 50px 50px 10px;
        }

        .coming-soon__inner__background {
            background-size: 115% 100%;
        }
    }
}
