@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%main-title {
    font-weight: $font-weight-light;
    margin-bottom: 0;
}

%main-subtitle {
    font-size: 18px;
    margin-bottom: 60px;
    color: $primary-text-color;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
    // For Google Chrome
    ::-webkit-scrollbar {
        width:  $size;
        height: $size;
    }

    ::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    ::-webkit-scrollbar-track {
        background: $background-color;
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}
