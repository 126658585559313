$light-success-color: #00d982 !default;
$success-color: #009447 !default;
$dark-success-color: #01600f !default;

$dark-primary-color: #00355d !default;
$primary-color: #005e8a !default;
$accent-color: $success-color !default;
$light-secondary-color: #0087bc !default;
$light-primary-color: #c8edfc !default;

$primary-text-color: #004b75 !default;
$dark-secondary-color: #003972 !default;
$secondary-color: #00a1d3 !default;
$light-secondary-color: #4eb5d4 !default;
$brown-color: #502b00 !default;
$gray-color: #525459 !default;
$light-gray-color: #e5e5e5 !default;
$muted-color: #cccccc !default;

$color-white-smoke: #f7f7f7 !default;

$white: #ffffff !default;
$black: #000 !default;

$red: #f44336 !default;
$yellow: #ffba00 !default;

$border-color: $light-primary-color !default;

$font-family-base: 'Lato';
$font-family-title: 'Yantramanav';
$font-size-base: 16px;
$line-height-base: 1.375;

$header-height-xs: 60px;
$header-height: 100px;
$header-height-sticky: 100px;

$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$body-color: $gray-color;
$body-bg: $white;
$paragraph-margin-bottom: 10px;

$link-color: $body-color;
$link-decoration: none;
$link-hover-color: inherit;
$link-hover-decoration: none;

$table-cell-padding: 10px;
$table-cell-padding: 10px;

$border-radius: 5px;

$breakpoints: ('xs': '0px',
    'sm': '600px',
    'md': '960px',
    'lg': '1280px',
    'xl': '1920px',
);

$grid-gutter-width: 20px;

$img-path: '/assets/image' !default;

//$mat-elevation-color: red; //#00a1d3 !default;
$mat-elevation-color: $primary-text-color !default;

$box-shadow-base: 0px 2px 4px -1px rgba(0, 75, 117, 0.2),
0px 4px 5px 0px rgba(0, 75, 117, 0.14),
0px 1px 10px 0px rgba(0, 75, 117, 0.12); // 4
$box-shadow-base-1: 0px 2px 1px -1px rgba(0, 75, 117, 0.2),
0px 1px 1px 0px rgba(0, 75, 117, 0.14),
0px 1px 3px 0px rgba(0, 75, 117, 0.12);
$box-shadow-base-2: 0px 3px 1px -2px rgba(0, 75, 117, 0.2),
0px 2px 2px 0px rgba(0, 75, 117, 0.14),
0px 1px 5px 0px rgba(0, 75, 117, 0.12);

$box-shadow-base-header: inset 0px -2px 2px 0px rgba(0, 75, 117, 0.14),
inset 0px -1px 5px 0px rgba(0, 75, 117, 0.12);

$transition-base: all 0.3s;

// -------- z --------------------

$z-header: 110;
$z-mat-drawer-container: 115;
$z-header-progress-bar: 120;

$s-40: 40px;
$s-100: 100px;

// -----------------------------

$scrollbars-foreground-color: $muted-color;
$scrollbars-background-color: $color-white-smoke;

@import 'mixins';
