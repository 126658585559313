%container {
  width: 100%;
  margin: 0 auto;
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
}

html {
  min-height: 100%;
  height: 100%;
  @include scrollbars(0.5em, $scrollbars-foreground-color, $scrollbars-background-color);
}

.container {
  max-width: 1292px;
  @extend %container;

  &--lg {
    max-width: 972px;
    @extend %container;
  }

  &--md {
    max-width: 600px;
    @extend %container;
  }

  &--sm {
    max-width: 510px;
    @extend %container;
  }

  &--xs {
    max-width: 462px;
    @extend %container;
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .container {
    max-width: 100%;
    padding-right: 4%;
    padding-left: 4%;
  }
}

.display-1 {
  font-size: 54px;
  font-family: $font-family-title;
  font-weight: $font-weight-light;
}

h1,
.h1 {
  font-size: 42px;
  font-weight: $font-weight-light;
}

h2,
.h2 {
  font-size: 36px;
  font-weight: $font-weight-light;
}

h3,
.h3 {
  font-size: 24px;
  font-weight: $font-weight-base;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5,
.lead {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

.txs {
  font-size: 13px;
}

.txxs {
  font-size: 12px;
}

// text color
.secondary-color {
  color: $secondary-color;
}

.primary-color {
  color: $primary-text-color;
}

a.primary-color:hover {
  color: $secondary-color;
}

.success-color {
  color: $success-color;
}

.light-gray-color {
  color: $light-gray-color;
}

.gray-color {
  color: $gray-color;
}

.text-muted {
  color: rgba($body-color, 0.5);
}

.yellow-color {
  color: $yellow;
}

.red-color {
  color: $red;
}
// ----------------------

.bg-white-smoke {
  background: $color-white-smoke;
}

//
.img-fluid {
  max-width: 100%;
}

// helpers
.mb {
  &--10 {
    margin-bottom: -10px;
  }

  &-0 {
    margin-bottom: 0;
  }

  &-5 {
    margin-bottom: 5px;
  }

  &-10 {
    margin-bottom: 10px;
  }

  &-20 {
    margin-bottom: 20px;
  }

  &-24 {
    margin-bottom: 24px;
  }

  &-30 {
    margin-bottom: 30px;
  }

  &-36 {
    margin-bottom: 36px;
  }

  &-40 {
    margin-bottom: 40px;
    //background: blue;
  }

  &-50 {
    margin-bottom: 50px;
  }

  &-60 {
    margin-bottom: 60px;
  }

  &-80 {
    margin-bottom: 80px;
  }

  &-100 {
    margin-bottom: 100px;
    //background: red;
  }
}

.mr {
  &-auto {
    margin-right: auto;
  }

  &--5 {
    margin-right: -5px;
  }

  &-5 {
    margin-right: 5px;
  }

  &-8 {
    margin-right: 8px;
  }

  &-10 {
    margin-right: 10px;
  }

  &-20 {
    margin-right: 20px;
  }
}

.ml {
  &-4 {
    margin-left: 4px;
  }

  &-10 {
    margin-left: 10px;
  }

  &-15 {
    margin-left: 15px;
  }
}

.pl {
  &-10 {
    padding-left: 10px;
  }

  &-16 {
    padding-left: 16px;
  }

  &-20 {
    padding-left: 20px;
  }

  &-22 {
    padding-left: 22px;
  }
}

.pr {
  &-30 {
    padding-right: 30px;
  }
}

.pt {
  &-20 {
    padding-top: 20px;
  }

  &-30 {
    padding-top: 30px;
  }

  &-40 {
    padding-top: 40px;
  }

  &-50 {
    padding-top: 50px;
  }

  &-60 {
    padding-top: 60px;
  }

  &-80 {
    padding-top: 80px;
  }
}

.pb {
  &-4 {
    padding-bottom: 4px;
  }

  &-20 {
    padding-bottom: 20px;
  }

  &-60 {
    padding-bottom: 60px;
  }

  &-80 {
    padding-bottom: 80px;
  }
}

// ------------------------------------

.va-b {
  vertical-align: bottom;
}

.va-m {
  vertical-align: middle;
}

// --------------------------------------

.fwm {
  font-weight: $font-weight-medium;
}

.fwr {
  font-weight: $font-weight-base;
}

.fwl {
  font-weight: $font-weight-light;
}

.fwb {
  font-weight: $font-weight-bold;
}

// ----------------------------------------

.fft {
  font-family: $font-family-title;
}

.ffb {
  font-family: $font-family-base;
}

// ----------------------------------------

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

// ----------------------------

.cp {
  cursor: pointer;
}

// ----------------------------

.bb-1 {
  border-bottom: 1px solid $color-white-smoke;
}

.bb-2 {
  border-bottom: 2px solid $color-white-smoke;
}

// ---------------------------------

.fab-btn {
  padding: 10px 0;

  & .mat-icon-button {
    padding: 0;
    min-width: 50px;
    line-height: 46px;
    height: 50px;
    border: 2px solid $light-primary-color;
    border-radius: 50%;
  }
}
.transparent-dialog {
  background: rgba(255, 255, 255, 0.95);
}

.video-dialog .mat-dialog-container {
  background: transparent;
  box-shadow: none;
  padding: 0;
}
.lightboxOverlay {
  opacity: 0.95;
  background: white;
}
.lightbox {
  .lb-data {
    .lb-close {
      display: none;
    }
  }
}
