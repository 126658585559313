@mixin md-icon-size($size: 24px) {
  height: $size;
  width: $size;
}

.i {
  &-16 {
    @include md-icon-size(16px);
  }

  &-18 {
    @include md-icon-size(18px);
  }

  &-20 {
    @include md-icon-size(20px);
  }

  &-24 {
    @include md-icon-size();
  }

  &-28 {
    @include md-icon-size(28px);
  }

  &-32 {
    @include md-icon-size(32px);
  }

  &-36 {
    @include md-icon-size(36px);
  }

  &-48 {
    @include md-icon-size(48px);
  }
}

@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
}

.mat-icon {
  &.md-16 {
    @include md-icon-size(16px);
  }

  &.md-18 {
    @include md-icon-size(18px);
  }

  &.md-24 {
    @include md-icon-size();
  }

  &.md-36 {
    @include md-icon-size(36px);
  }

  &.md-50 {
    @include md-icon-size(50px);
  }

  &.md-60 {
    @include md-icon-size(60px);
  }

  &.md-96 {
    @include md-icon-size(96px);
  }
}

.icon-up {
  color: $light-success-color;
  transform: rotate(-90deg);
}

.icon-down {
  color: $red;
  transform: rotate(90deg);
}

.icon-equal {
  color: $yellow;
}

.icon-info {
  color: $muted-color;
}
